@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap);
/*! normalize.css v8.0.1 */html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0}main{display:block}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;-webkit-text-decoration:underline dotted;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}[hidden]{display:none}
@font-face {
  font-family: 'Telegraf';
  src: url(../../static/media/Telegraf-UltraBold.6b12ecc0.woff2) format("woff2"), url(../../static/media/Telegraf-UltraBold.a8ccafa4.woff) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url(../../static/media/Telegraf-UltraLight.fc640ee9.woff2) format("woff2"), url(../../static/media/Telegraf-UltraLight.c42cc60f.woff) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telegraf';
  src: url(../../static/media/Telegraf-Regular.9960f518.woff2) format("woff2"), url(../../static/media/Telegraf-Regular.33214df1.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url(../../static/media/AvenirNextLTPro-Heavy.fad20ab2.otf) format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'Surt-Medium';
  src: url(../../static/media/Surt-Normal-Medium.5f35d4b3.woff2) format("woff2"), url(../../static/media/Surt-Normal-Medium.ce57a437.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
}

html {
  font-size: 14px;
}

body {
  font-size: 1rem;
  font-family: 'Surt-Medium', sans-serif;
  background-color: #f8f5f2;
}

h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 2.143rem;
  line-height: 1.167;
  letter-spacing: 0.015em;
  font-weight: bold;
  font-family: 'Avenir', sans-serif;
}

h2 {
  font-size: 1.714rem;
  font-family: 'Avenir', sans-serif;
  line-height: 1.167;
  font-weight: bold
}

@media (max-width: 616px) {

h2 {
    font-size: 1.429rem;
    line-height: 1.15
}
  }

h3 {
  font-family: 'Avenir', sans-serif;
  font-size: 1.286rem;
  line-height: 1.278;
  font-weight: 600;
}

h4, h5, h6 {
  font-family: 'Avenir', sans-serif;
  font-size: 0.857rem;
  line-height: 1.33;
  font-weight: normal;
}

p {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.29;
}

.container {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box  
}

@media (min-width: 616px) {

.container {
    max-width: 580px  
}
  }

@media (min-width: 808px) {

.container {
    max-width: 760px  
}
  }

@media (min-width: 1092px) {

.container {
    max-width: 1280px;
    padding: 0 20px  
}
  }

.full-container {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
}

.full-container img {
    width: 300px;
    height: auto;
  }

.full-report {
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;  
  width: 100%; 
}

.main {
  background-color: #fff;
  color: #2d3e50;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 40px;
  margin-bottom: 90px;
  margin-top: 100px !important;
  position: relative;
  z-index: 2
}

@media (max-width: 616px) {

.main {
    border-radius: 0;
    padding: 25px
}    
  }

.full-main {
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 65px;
  box-sizing: border-box;
}

.content {
  max-width: 72%;
  width: 100%
}

@media (max-width: 1092px) {

.content {
    max-width: 100%
}
  }

.full-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column
}

@media (max-width: 1092px) {

.full-content {
    max-width: 100%
}
  }

.armchair-icon {
  width: 41px;
  height: 50px;
}

.pig-icon, .aid-icon {
  width: 50px;
  height: 44px;
}

.dollar-icon{
  width: 22px;
  height: 44px;
}

.man-icon{
  width: 40px;
  height: 45px;
}

.fileDownload-icon{
  width: 15px;
  height: 20px;
}

.share-icon{
  width: 23px;
  height: 20px;
}

.print-icon{
  width: 20px;
  height: 20px;
}

.button-yellow {
  font-size: 1rem;
  line-height: 1.28;
  background-color: #fef627;
  border: 1px solid #000;
  padding: 12px 0;
  color: #000;
  border-radius: 32px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Surt-Medium', sans-serif;
  font-weight: bold;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  transition: color .2s ease-out,background-color .2s ease-out,border-radius .5s ease-out
}

.button-yellow:hover {
    transition: color .2s ease-out,background-color .2s ease-out,border-radius .35s ease-in-out;
    border-radius: 8px;
  }

.button-white {
  font-size: 1rem;
  line-height: 1.28;
  color: #000;
  padding: 12px 0;
  background-color: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Surt-Medium', sans-serif;
  font-weight: bold;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  cursor: pointer;
  outline: none;
  transition: color .2s ease-out,background-color .2s ease-out,border-radius .5s ease-out
}

.button-white:hover {
    transition: color .2s ease-out,background-color .2s ease-out,border-radius .35s ease-in-out;
    border-radius: 8px;
  }

.button-disabled {
  background-color: #BDBDBD;
  color: #000;
  pointer-events: none;
  font-weight: 400;
  border-radius: 32px;
  border: none;
}

.button + .button {
  margin-left: 24px
}

@media (max-width: 616px) {

.button + .button {
    margin-left: 10px
}
  }

.nav-buttons{
  width: 100%;
  display: flex; 
}

.button-block{
  padding-right: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative
}

@media (max-width: 1092px) {

.button-block{
    padding-right: 0;
    justify-content: space-between
}    
  }

.button-mobile {
  display: none;
  text-align: center
}

@media (max-width: 616px) {

.button-mobile {
    display: block
}
  }

@media (max-width: 616px) {

.button-desktop {
    display: none
}
  }

.popup {
  margin-left: auto;
  margin-right: auto;  
  max-width: 1240px; 
  height: 100%;
}

.popup-content {
    background: none;
    width: auto;
    border: none;
    padding: 15px;         
  }

.popup-overlay {
    overflow-y: auto;
  }

.header-main {
  background-color: #f8f5f2;
  transition: all .3s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;  
  font-family: 'Avenir', sans-serif;
  font-weight: 400;  
}

.header-main .container{  
  position: relative;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 1120px;
  flex-flow: row wrap;
  z-index: 1;
}

.header-main__logo-wrapper {
  width: 30%;
  display: flex;
  align-items: flex-end;
}

.header-main__logo {
  display: block;
}

.header-main__logo img, .header-main__logo svg {
  display: block;
  transition: -webkit-filter .2s ease-out;
  transition: filter .2s ease-out;
  transition: filter .2s ease-out, -webkit-filter .2s ease-out;
}

.header-main__logo:before {
  display: none !important;
}

.header-main__logo-text {
  top: 6px;
  left: 8px;
}

.header-main ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.header-main a {
  display: flex;
  align-items: center;
  position: relative;
  color: #187BE3;
  font-weight: 400;
  text-decoration: none;
}

.header-main a span {
  display: flex;
  align-items: center;
  white-space: nowrap;
  word-break: keep-all;
}

.header-main a svg {
  margin-right: 10px;
}

.header-main a svg path {
  fill: #fff;
}

.header-main__container {
  align-items: center;
  width: 100%;
}

.header-main__container.container {
  justify-content: space-between;
  max-width: 1280px;
}

@media (min-width: 1025px) {
  .header-main {
    padding: 40px 0 20px;
  }
  .header-main.affixed {
    padding: 27px 0 28px;    
  }  
  .header-main.affixed a svg {
    -webkit-filter: invert(1);
            filter: invert(1);
  }
  .header-main.affixed a svg path {
    fill: #26275f;
  }  
  .header-main__logo {
    width: 170px;
    height: auto;
  }
  .header-main li {
    position: relative;
    margin: 0 0 0 65px;
  }
  .header-main li:last-child a {
    font-weight: 800;
  }
}

@media (min-width: 1025px) and (max-width: 768px) {
  .header-main li {
    margin: 0 8px;
  }
}

@media (min-width: 1025px) {
  .header-main li:last-of-type {
    margin-right: 0;
  }
  .header-main li:first-of-type {
    margin-left: 0;
  }
  .header-main li:hover .sub-menu {
    display: flex;
  }
  .header-main a {
    font-size: 15px;
    position: relative;
  }
  .header-main .sub-menu {
    display: none;
    position: absolute;
    z-index: 3;
    padding-top: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    top: 18px;
    flex-direction: column;
    align-items: flex-start;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    min-width: 140px;
  }
  .header-main .sub-menu li {
    background-color: #fff;
    text-align: center;
    font-size: 80%;
    margin: 0;
    padding: 5px 15px;
    width: 100%;
  }
  .header-main .sub-menu li:first-child {
    padding-top: 10px;
  }
  .header-main .sub-menu li:last-child {
    padding-bottom: 10px;
  }
  .header-main .sub-menu a {
    display: block;
    width: 100%;
    text-align: center;
    color: #0B1028;
  }
  .header-main .sub-menu a:hover {
    color: #26275f;
  }
  .header-main .mega {
    display: flex;
  }
  .header-main .mega:hover .sub-menu {
    display: flex;
  }
  .header-main .mega .sub-menu {
    flex: 0 0 100%;
    max-width: 100%;
    display: none;
    flex-flow: row wrap;
    width: 460px;
    left: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    padding-bottom: 25px;
    padding-right: 25px;
  }
  .header-main .mega .sub-menu li {
    background-color: #fff;
    flex: 0 0 33.333%;
    width: 33.333%;
    max-width: 33.333%;
    padding: 10px 30px 30px;
  }
  .header-main .mega .sub-menu li a {
    text-align: left;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 300;
  }
  .header-main .mega .sub-menu .mega-title {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding-top: 30px;
    padding-bottom: 0;
  }
}

@media (min-width: 1025px) and (max-width: 575px) {
  .header-main .mega .sub-menu .mega-title {
    display: none;
  }
}

@media (min-width: 1025px) {
  .header-main .mega .sub-menu .mega-title a {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3rem;
  }
}

@media (max-width: 1024px) {
  .header-main {
    align-items: baseline;
    padding: 15px 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }  
  .header-main.affixed .hamburger-inner, .header-main.affixed .hamburger-inner::before, .header-main.affixed .hamburger-inner::after {
    background-color: #26275f;
  }  
  .header-main__logo {
    height: auto;
  }
  .header-main__logo svg {
    height: auto;
    width: 158px;
  }
  .header-main ul {
    background-color: #26275f;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 14px;
  }
  .header-main li {
    transition: all .3s ease;
    width: 100%;
    padding: 15px 25px;
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
    box-sizing: border-box;
  }
  .header-main li:hover .sub-menu {
    display: block;
  }
  .header-main a {
    font-size: 17px;
    line-height: 20px;
  }
  .header-main__nav {
    opacity: 0;
    transition: translateX 3s 2s ease, opacity .2s .5s ease;
    -webkit-transform: translateX(-115%);
            transform: translateX(-115%);
    width: 0;
    height: 0;
    background-color: #26275f;
  }
  .header-main__nav.is-active {
    transition: opacity .2s .5s ease, -webkit-transform .3s ease;
    transition: transform .3s ease, opacity .2s .5s ease;
    transition: transform .3s ease, opacity .2s .5s ease, -webkit-transform .3s ease;
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    position: fixed;
    top: 57px;
    left: 0;
    width: 100%;
    height: calc(100vh - 57px);
  }
  .header-main__nav.is-active:before {    
    background-position: center;
    width: 100%;
    height: 100%;
    display: block;
    content: '';
    position: absolute;
  }
  .header-main__nav.is-active li {
    transition-delay: 2s;
  }
  .header-main__nav.is-active li:nth-child(1) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 0.15s;
  }
  .header-main__nav.is-active li:nth-child(2) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 0.3s;
  }
  .header-main__nav.is-active li:nth-child(3) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 0.45s;
  }
  .header-main__nav.is-active li:nth-child(4) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 0.6s;
  }
  .header-main__nav.is-active li:nth-child(5) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 0.75s;
  }
  .header-main__nav.is-active li:nth-child(6) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 0.9s;
  }
  .header-main__nav.is-active li:nth-child(7) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 1.05s;
  }
  .header-main__nav.is-active li:nth-child(8) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 1.2s;
  }
  .header-main__nav.is-active li:nth-child(9) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 1.35s;
  }
  .header-main__nav.is-active li:nth-child(10) {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-delay: 1.5s;
  }
  .header-main__nav ul .menu-item-has-children > a:before {
    border-bottom: none;
    left: unset;
    display: block;
    position: absolute;
    content: '\f054';
    color: #26275f;
    right: 5px;
    font-family: "Font Awesome 5 Free", sans-serif;
    font-size: 2rem;
    font-weight: 900;
  }
  .header-main__nav ul a {
    display: block;
    width: 100%;
  }
  .header-main .sub-menu {
    display: none;
    z-index: 3;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    left: 0;
    min-width: 140px;
  }
  .header-main .sub-menu li {
    text-align: left;
    font-size: 80%;
    margin: 0;
    padding: 0 15px 15px;
    width: 100%;
  }
  .header-main .sub-menu li:first-child {
    padding-top: 10px;
  }
  .header-main .sub-menu li:last-child {
    padding-bottom: 10px;
  }
  .header-main .sub-menu a {
    font-size: 1.6rem;
    display: block;
    width: 100%;
    color: #fff;
    text-align: left;
  }
  .header-main .sub-menu a:before {
    display: none !important;
  }
}

.header-main__container, .header-main__nav, .header-main ul {
  background-color: inherit;
}

@media (max-width: 1024px) {
  .hamburger {
    display: block;
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

.hamburger {
  cursor: pointer;
  transition: all .15s ease;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 11;
  outline: 0px transparent;
}

@media (max-width: 768px) {
  .hamburger {
    padding: 0;
  }
}

.hamburger > div {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}

.hamburger-box {
  width: 25px;
  height: 24px;
  top: 1px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -3px;
  position: relative;
  left: 50%;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #26275f;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -9px;
}

.hamburger-inner::after {
  bottom: -9px;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner {
  width: 24px;
  height: 3px;
  border-radius: 0;
  left: 0;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner:before, .hamburger--spin.is-active .hamburger-inner:after {
  width: 24px;
  height: 3px;
  border-radius: 0;
}

body.lock-scroll {
  overflow: hidden;
}

.first-page .content {
    margin: 24px auto;
    text-align: center
  }

@media (max-width: 1300px) and (min-width: 1092px) {
  .first-page .content {
      margin: 0 auto
  }
    }

@media (max-width: 616px) {
  .first-page .content {
      margin: 10px auto
  }
    }

.first-page__title {
    margin-bottom: 28px;
    text-transform: uppercase
  }

@media (max-width: 1300px) and (min-width: 1092px) {

  .first-page__title {
      margin-bottom: 15px
  }
    }

.first-page__descr {
    align-items: center;
    flex-direction: column;
    display: flex;    
  }

.first-page__descr p {
      color: #2d3e50;
      white-space: break-spaces;
      margin-bottom: 0
    }

.first-page__descr p:last-of-type {
        margin-bottom: 32px;
        display: flex;
        justify-content: center;
      }

.first-page__image {
    width: 400px;
    height: 200px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 24px;
    border: 1px solid #000;
    border-radius: 32px;
    padding: 0 32px;
    display: flex;
    justify-content: center
  }

@media (max-width: 1300px) and (min-width: 1092px) {

  .first-page__image {
      margin-bottom: 20px;
      width: 350px;
      height: 150px;
      padding: 16px;
  }
      
      .first-page__image img {
        width: 95%;
      }
    }

@media (max-width: 616px) {

  .first-page__image {
      width: 200px;
      height: 100px
  }
    }

.first-page__button {
    display: flex;
    justify-content: center;
  }

@media (max-width: 616px) {

.first-page {
    border-radius: 12px;
    margin-left: 16px;
    margin-right: 16px
}
  }

.sidebar {
  max-width: 28%;
  width: 100%;  
  position: relative;
}

.sidebar__inner {
    padding-left: 40px;
    height: 100%;
  }

.sidebar__inner-mobile {
      background: #fff;
      padding: 30px 24px 50px;      
      border-radius: 8px 8px 0px 0px;      
      position: relative;
    }

.sidebar__block-text {
    margin-bottom: 36px;
    color: #2d3e50;
  }

.sidebar__block-link p {
      color: #2d3e50;
      margin-bottom: 20px;
    }

.sidebar__report-block-text{
    margin-bottom: 36px;
    color: #2d3e50
  }

.sidebar__report-block-text:last-child {
      margin-bottom: 0;
    }

.sidebar__report-block-text p{
      margin-bottom: 16px;
    }

.sidebar_report{
    padding-top: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 90px;     
  }

.sidebar__title, .sidebar__subtitle {
    font-family: 'Avenir', sans-serif;
    text-transform: uppercase;
    margin-bottom: 12px;
    color: #2d3e50;
  }

.sidebar__description {
  }

.sidebar__subtitle {
    font-weight: 600;
  }

.sidebar__report-title{
    font-family: 'Avenir', sans-serif;
    line-height: 1.167;
    margin-bottom: 16px;
  }

.sidebar__button{
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
  }

.sidebar__button-link {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-decoration: none;
      color: #187BE3;
      display: inline-block;
    }

@media (max-width: 425px) {

  .sidebar__button{
      width: 100%
  }     
    }

.sidebar__button-send{
    padding: 12px 16px
  }

@media (max-width: 425px) {

  .sidebar__button-send{
      width: 100%;    
      box-sizing: border-box
  }  
    }

.sidebar__link {
    font-size: 1rem;
    line-height: 1.29;
    text-decoration: none;
    color: #187BE3;
  }

.sidebar:before {
    content: '';
    width: 1px;    
    background-color: #CDCDCD;
    border-radius: 2px;
    position: absolute;    
    left: 0;
    top: 0;
    bottom: 0;
  }

.sidebar_form {
    padding-top: 50px;
    display: flex;
    align-items: center
  }

.sidebar_form:before {
      content: none;
    }

.sidebar__card {
    background: #fff;
    border: 1px solid #CDCDCD;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: row
  }

.sidebar__card:not(:last-child) {
      margin-bottom: 27px;
    }

.sidebar__card-image {
    max-width: 60px;
    height: auto;
    margin-right: 16px;
    width: 100%;
  }

.sidebar__card-title {
    color: #2d3e50;
    margin-bottom: 5px;
  }

.sidebar__card-descr {
    color: #2d3e50;
    margin-bottom: 10px;
  }

.sidebar__card-button {
    margin-bottom: 8px;
  }

.sidebar__card-button .button {
      padding: 12px 15px;
    }

.sidebar__card-title + .sidebar__card-button {
    margin-top: 10px;
  }

.sidebar__sticky {
    align-items: flex-start; 
  }

.sidebar__popup-content {
      padding: 0;
      width: 100%;      
      margin-bottom: 0 !important;
    }

.sidebar__close {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 29px;
    right: 29px;
  }

.sidebar__close svg {
      width: 14px;
      height: 14px;
    }

@media (max-width: 1092px) {

.sidebar {
    display: none
}
  }

.ranger {
  height: 4px;
  background-color: #e2deea;
  border-radius: 8px;
  margin: 85px 20px 0
}

@media (max-width: 1092px) {

.ranger {
    display: none
}
  }

.ticks-item {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Surt-Medium', sans-serif;
  color: #828282;
  top: 40px;
  width: auto;
  width: 100% !important;
  max-width: 83px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.progress-bar {
  margin-bottom: 45px;
  padding-right: 65px;
  margin-top: 10px;
  padding-left: 20px
}

@media (max-width: 1092px) {

.progress-bar {
    padding: 0
}
  }

.progress-tracker {
  display: flex;
  justify-content: space-between;
}

.progress-tracker__step {
    list-style-type: none;
    width: 100%;
    border-top: 2px solid #BDBDBD;
    position: relative
  }

.progress-tracker__step:before {
      content: '';
      background: #fff;
      border: 2px solid #BDBDBD;
      box-sizing: border-box;
      border-radius: 45px;
      height: 20px;
      width: 20px;
      position: relative;
      float: left;
      top: -10px;
    }

.progress-tracker__step span {
      position: absolute;
      bottom: -7px;
      white-space: nowrap;
      font-size: 0.857rem;
      font-family: "Surt-Medium", sans-serif;
      line-height: 1.25;
      color: #BDBDBD;
      text-transform: capitalize;
      -webkit-transform: translate(calc(-50% + 10px), 0);
              transform: translate(calc(-50% + 10px), 0);
      left: 0;
    }

.progress-tracker__step:last-child {
      border: none;
      width: auto;
    }

.progress-tracker__step.active:before {
        border-color: #00D0C4;
      }

.progress-tracker__step.active span {
        color: #00D0C4;
        font-weight: bold;
      }

.progress-tracker__step.complete:before {
        border-color: #00D0C4;

      }

.progress-tracker__step.complete:after {
        content: '';
        background-image: url(../../static/media/check.e94fc3d0.svg);
        background-repeat: no-repeat;
        background-size: initial;
        background-position: center;
        transition: all .3s ease;
        position: absolute;
        left: 3px;
        top: -4px;
        width: 13px;
        height: 9px;

      }

.progress-tracker__step.complete span {
        color: #00D0C4;
      }

@media (max-width: 808px) {
      .progress-tracker__step:after {
        counter-increment: section;
        content: counter(section);
        position: absolute;
        left: 6px;
        top: -6px;
        font-family: 'Surt-Medium', sans-serif;
        font-size: 0.857rem;
        line-height: 1.167;
        color:#BDBDBD;
      }
      .progress-tracker__step span {
        display: none;
      }
        .progress-tracker__step.active span {
          display: block;
        }

        .progress-tracker__step.active:after {
          content: '';
        }

    }

@media (max-width: 808px) {

.progress-tracker {
    counter-reset: section
}
  }

.result-progress-bar, .report__progress{
  border-radius: 50%;
}

.result-progress-bar .circle .mask, .result-progress-bar .circle .fill, .report__progress .circle .mask, .report__progress .circle .fill {
    position: absolute;
    border-radius: 50%;
  }

.result-progress-bar .circle .mask.full, .result-progress-bar .circle .fill, .report__progress .circle .mask.full, .report__progress .circle .fill {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

.result-progress-bar .inside-circle, .report__progress .inside-circle {
    border-radius: 50%;
    background: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #404040;
    flex-wrap: wrap;
    flex-direction: column;
  }

.result-progress-bar .inside-circle .number, .report__progress .inside-circle .number {
      font-family: 'Surt-Medium', sans-serif;
    }

.result-progress-bar .inside-circle .mark, .report__progress .inside-circle .mark {
      font-size: 1.143rem;
      line-height: 1.25;
      font-weight: 600;
      font-family: "Surt-Medium", sans-serif;
    }

.result-progress-bar{
  width: 146px;
  height: 146px;
  background: #E8E5E5;
}

.result-progress-bar .circle .mask, .result-progress-bar .circle .fill {
    width: 146px;
    height: 146px;
  }

.result-progress-bar .circle .mask {
    clip: rect(0px, 146px, 146px, 72px);
  }

.result-progress-bar .circle .mask .fill {
    clip: rect(0px, 73px, 146px, 0px);
    
  }

.result-progress-bar .inside-circle {
    width: 116px;
    height: 116px;
    margin-top: 15px;
    margin-left: 15px;
  }

.result-progress-bar .inside-circle .number {
      font-size: 2.571rem;
      line-height: 1.167;
      font-weight: 600;
    }

.report__progress{
  width: 144px;
  height: 144px;
  background: #fff;
}

.report__progress .circle .mask, .report__progress .circle .fill {
    width: 144px;
    height: 144px;
  }

.report__progress .circle .mask {
    clip: rect(0px, 144px, 144px, 72px);
  }

.report__progress .circle .mask .fill {
    clip: rect(0px, 73px, 144px, 0px);
    background-color: #00D0C4;
  }

.report__progress .inside-circle {
    width: 90px;
    height: 90px;
    margin-top: 27px;
    margin-left: 27px;
    background-color: #E6FFFE;
  }

.report__progress .inside-circle .number {
      font-size: 2.143rem;
      line-height: 1.167;
      font-weight: 500;
    }

@-webkit-keyframes fill {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(139deg);
            transform: rotate(139deg);
  }
}

@keyframes fill {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(139deg);
            transform: rotate(139deg);
  }
}

.pointColor-default{
    background-color: #187BE3;
  }

.pointColor-red{
    background-color: #B20000;
  }

.pointColor-yellow{
    background-color: #ECD71B;
  }

.pointColor-green{
    background-color: #338955;
  }

.pointColor-gold{
    background-color: #E1A13F;
  }

.text {
  padding-right: 40px;
}

.text__inner {
    display: flex;
    width: 100%;
  }

.text__link {
    width: 100%;    
    font-size: 1.286rem;
    line-height: 1.278;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #187BE3;
    padding-bottom: 27px;
    cursor: pointer;
    position: relative
  }

@media (max-width: 1300px) and (min-width: 1092px) {

  .text__link {
      padding-bottom: 15px
  }
    }

.text__link-mobile {
      display: none
    }

@media (max-width: 1092px) {

    .text__link-mobile {
        display: flex;        
        padding-bottom: 0;
        margin-bottom: 38px
    }     
      }

.text__link img {
      width: 23px;
      height: auto;
      margin-right: 7px;
    }

@media (max-width: 1092px) {

  .text__link {
      max-width: 100%
  }      
  
      .text__link:before {
        display: none;
      }
    }

.text__content {
    display: flex;
    flex-direction: column;
    max-width: 140px
  }

@media (max-width: 1300px) and (min-width: 1092px) {

  .text__content {
      max-width: 100%
  }
    }

@media (max-width: 616px) {

  .text__content {
      text-align: initial;
      max-width: 100%;
      width: 100%
  }
    }

.text__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 23px
  }

@media (max-width: 616px) {

  .text__buttons {
      justify-content: space-between
  }
    }

.text__checkbox {
    position: relative;
    width: 185px;
    display: flex;
    margin-bottom: 40px;
  }

.text__checkbox input[type="checkbox"], .text__checkbox input[type="radio"] {
    position: absolute;
    opacity: 0
  }

.text__checkbox input[type="checkbox"]:checked + label, .text__checkbox input[type="radio"]:checked + label {
      background-color: #187BE3;
      box-shadow: 0px 8px 9px rgba(0, 0, 0, 0.1), 0px 14px 11px rgba(0, 0, 0, 0.07), 0px 6px 14px rgba(0, 0, 0, 0.06);
      transition: all .3s ease;

    }

.text__checkbox input[type="checkbox"]:checked + label .manCorp-icon, .text__checkbox input[type="radio"]:checked + label .manCorp-icon{
        background-image: url(../../static/media/manCorp-white.77cee1f3.svg);
      }

.text__checkbox input[type="checkbox"]:checked + label [class$=__icon], .text__checkbox input[type="radio"]:checked + label [class$=__icon] {
        fill: #fff;
        transition: all .3s ease;
      }

.text__checkbox input[type="checkbox"]:checked + label span, .text__checkbox input[type="radio"]:checked + label span {
        color: #fff;
        transition: all .3s ease;
      }

.text__checkbox input[type="checkbox"]:checked + label:before, .text__checkbox input[type="radio"]:checked + label:before {
        border-color: #fff;
        background-color: #fff;
        transition: all .3s ease;
      }

.text__checkbox input[type="checkbox"]:checked + label:after, .text__checkbox input[type="radio"]:checked + label:after {
        content: '';
        background-image: url(../../static/media/check-blue.d523602b.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 20px;
        left: 19px;
        width: 13px;
        height: 13px;
        transition: all .3s ease
      }

@media (max-width: 1300px) and (min-width: 1092px) {

      .text__checkbox input[type="checkbox"]:checked + label:after, .text__checkbox input[type="radio"]:checked + label:after {
          top: 9px;
          left: 8px
      }
        }

.text__checkbox input[type="checkbox"]:checked + label img + img, .text__checkbox input[type="radio"]:checked + label img + img {        
        visibility: visible;
      }

@media (max-width: 616px) {
        .text__checkbox input[type="checkbox"]:checked + label:after, .text__checkbox input[type="radio"]:checked + label:after {
          top: 16px;
          left: 15px;
        }
      }

.text__checkbox input[type="checkbox"]:disabled + label:before, .text__checkbox input[type="radio"]:disabled + label:before {
        border-color: #fff;
        background-color: #fff;
        transition: all .3s ease;
      }

.text__checkbox input[type="checkbox"]:disabled + label:after, .text__checkbox input[type="radio"]:disabled + label:after{
        content: none;
      }

.text__checkbox label {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 1px 7px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    align-items: center
  }

.text__checkbox label:before {
      content: '';
      width: 100%;
      max-width: 14px;
      height: 14px;
      border-radius: 2px;
      border: 2px solid #828282;
      top: 0;
      left: 0;
      position: relative;
      align-self: flex-start;
    }

@media (max-width: 1300px) and (min-width: 1092px) {
  .text__checkbox label {
      padding: 5px;
      justify-content: flex-start
  }
    }

@media (max-width: 616px) {
  .text__checkbox label {
      flex-direction: row;
      padding: 12px
  }
      .text__checkbox label:before {
        margin-right: 4px;
      }
    }

@media (min-width: 1092px) {

  .text__checkbox {
      width: calc((100% - 54px) / 4)
  }
    }

@media (max-width: 616px) {

  .text__checkbox {
      width: 100%;
      margin-bottom: 12px
  }
      .text__checkbox:last-child{
        margin-bottom: 35px;
      }
    }

.text__checkbox:not(:last-child) {
    margin-right: 18px
  }

@media (max-width: 616px) {

  .text__checkbox:not(:last-child) {
      margin-right: 0
  }
    }

.text__icon {
    fill: #00D0C4;
    margin-top: 16px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    position: relative;
  }

.text__icon img {
      width: 100%;
      height: 100%;
      display: block;
    }

.text__icon img + img {
      position: absolute;
      visibility: hidden;
      transition: all .3 ease-in-out;
    }

@media (max-width: 1300px) and (min-width: 1092px) {

  .text__icon {
      margin-top: 0;
      margin-bottom: 10px
  }
    }

@media (max-width: 616px) {

  .text__icon {
      margin-right: 25px;
      margin-bottom: 0;
      margin-top: 0;
      width: 50px;
      height: auto
  }
    }

.text__title {
    color: #2d3e50;
    margin-bottom: 28px;
    text-transform: uppercase
  }

@media (max-width: 1300px) and (min-width: 1092px) {

  .text__title {
      margin-bottom: 15px
  }
    }

.text__title_form {
      color: #2d3e50;
      margin-bottom: 23px;
    }

.text__subtitle, .text__descr  {
    margin-bottom: 20px;
  }

.text__descr {
    color: #2d3e50
  }

@media (max-width: 1300px) and (min-width: 1092px) {
  
  .text__descr {
      margin-bottom: 10px
  }
    }

@media (max-width: 616px) {
  
  .text__descr {
      margin-bottom: 0
  }
    }

.text__subtitle {
    color: #2d3e50;
  }

.text__title-card {
    margin-bottom: 18px;
    font-size: 1.286rem;
    line-height: 1.278;
    font-weight: 600;
    color: #2d3e50;
    text-transform: uppercase
  }

@media (max-width: 1300px) and (min-width: 1092px) {

  .text__title-card {
      margin-bottom: 10px
  }
    }

@media (max-width: 616px) {

  .text__title-card {
      margin-bottom: 4px
  }
    }

@media (max-width: 1092px) {

.text {
    padding-right: 0
}
  }

.text div.text__field {
    width: 100%;
    margin-bottom: 0;
  }

.text div.text__field input {
      max-width: 100%;
    }

.manCorp-icon{
  background-image: url(../../static/media/manCorp.0df0e924.svg);
  width: 57px;
  height: 48px;
}

.result__header{    
    margin-bottom: 24px;
  }

.result__header-text {
      max-width: 72%;
      width: 100%;
    }

.result__title{
    color: #2d3e50;
    margin-bottom: 16px;
    text-transform: uppercase;

  }

.result__date{
    line-height: 1.14;
    color: #2d3e50;
    margin: 0;
  }

.result__inner {
    background-color: #F9F9F9;
    padding: 40px;
    margin-right: 40px;    
  }

.result__card{
    background-color: #DFF7F7;    
    border-radius: 8px 8px 0px 0px;
    padding: 40px 36px 40px 40px;
    display: flex;
    justify-content: space-between;
  }

.result__card-img img {
        width: 127px;
        height: 172px;
        display: block;
      }

.result__card-text{
      padding-left: 40px;
    }

.result__card-title {
      color: #2d3e50;
      margin-bottom: 8px;
    }

.result__card-subtitle {
      color: #2d3e50;
      font-family: 'Surt-Medium', sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 16px;
    }

.result__card-descr{
      color: #2d3e50;
      margin-bottom: 25px;
    }

.result__card-apply-to-join{
      color: #2d3e50;
    }

.result__card-apply-to-join a {
        text-decoration: none;
        color: #187BE3;
      }

.result__card-reanalyse {
      color: #2d3e50;
    }

.result__card-reanalyse p {
        margin-bottom: 0;
      }

.result__card-reanalyse a {
        text-decoration: none;
        color: #187BE3;
      }

.result__buttons {    
    display: flex;    
  }

.result__buttons .button-white {
      padding: 12px;
    }

.result__buttons .button-yellow {
      padding: 12px 16px;
      margin-right: 24px;
      margin-bottom: 25px;
    }

.result__buttons .button + .button {
      margin-left: 0;
    }

.result__bullet {
    background-color: #fff;    
    padding: 24px 32px;
    border-radius: 0 0 8px 8px;
  }

.result__sidebar {
    display: none;
  }

.card-list{
  list-style-type: none;
  padding-left: 18px;  
  margin-top: 15px;  
}

.card-list__item{
    margin-bottom: 15px;
    position: relative;
  }

.card-list__item p {
      margin: 0;
    }

.card-list__item:last-child {
      margin-bottom: 0;
    }

.card-list__item::before{
      content: '';
      background-image: url(../../static/media/circle.43a47f27.svg);
      position: absolute;
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      background-size: contain;
      left: -20px;
      top: 3px;
    }

@media (max-width: 1092px) {

    .result__inner {
      margin-right: 0;
    }    

    .result__sidebar {
      display: block;
      background: #F9F9F9;
      padding: 0 40px 40px;
    }
}

@media (max-width: 808px) {
    
    .result__card {
      flex-direction: column;
    }
      
        .result__card-img img {
          margin: 0 auto 20px;
        }

      .result__card-text{
        padding-left: 0;
      }
      
      .result__card-title {
        text-align: center;
      }

      .result__card-subtitle {
        text-align: center;
      }

      .result__card-descr{ 
        margin-bottom: 20px;
      }
}

@media (max-width: 616px) {        
  
      .result__header-text {
        max-width: 100%;        
      }

    .result__inner {
      padding: 32px 15px;
    }

    .result__card {
      padding: 32px 20px;
    }

      .result__card-subtitle {
        font-size: 20px;
        line-height: 23px;
      }

    .result__buttons {
      flex-direction: column;
      align-items: center;
    }

      .result__buttons .button-yellow {
        margin-right: 0;                             
      }
  
      .result__buttons .button {      
        width: auto;
      } 
    
    .result__sidebar {      
      padding: 0 15px 32px;
    }
}

@media (max-width: 425px) {    

    .result__buttons {      
      align-items: normal;      
    }
}

.form {
  margin-bottom: 24px;
}

.form-field {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
  }

.form-field__select {
      width: 50%;
      margin-bottom: 36px
    }

@media (max-width: 808px) {    

    .form-field__select {
        width: 100%
    }
      }

.form-field input {
      max-width: 328px;
      padding: 12px 16px;
      border: 1px solid #CDCDCD;
      box-sizing: border-box;
      border-radius: 3px;
      letter-spacing: 0.15px;
      color: #2d3e50;
      line-height: 1.5;
      font-size: 1.143rem;
      font-family: 'Surt-Medium', sans-serif;
      outline-color: #2684FF
    }

.form-field input::-webkit-input-placeholder {
        color: #7D7D7D;
      }

.form-field input::placeholder {
        color: #7D7D7D;
      }

@media (max-width: 1300px) {

    .form-field input {
        max-width: 80%
    }
      }

@media (max-width: 808px) {

    .form-field input {
        max-width: 100%
    }
      }

.form-field label {
      font-size: 0.857rem;
      line-height: 1.33;
      letter-spacing: 0.4px;
      color: #7D7D7D;
      margin-bottom: 4px;
      font-family: 'Surt-Medium', sans-serif;
    }

.form-field span {
      color: #B20000;
    }

.form-field select:not(:focus):invalid {
      color: #7D7D7D;
    }

@media (max-width: 808px) {

  .form-field {
      width: 100%
  }
    }

.form__fields {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

.form__buttons {
    margin-top: -4px;
    display: flex;
  }

.form__buttons .button {
      padding: 12px 16px;      
    }

@media (max-width: 616px) {
  .form__buttons {
      flex-direction: column;
      align-items: center;
      width: 100%;
  }

      .form__buttons .button {
        width: auto;     
      }

      .form__buttons .button-white {
        margin-left: 0;
        margin-top: 20px;
      }
    }

.select-field {
  max-width: 328px
}

@media (max-width: 1300px) {

.select-field {
    max-width: 80%
}
  }

@media (max-width: 808px) {

.select-field {
    max-width: 100%
}
  }

.select-field span {
    display: none;
  }

.select-field > div {
  padding: 0 16px;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 3px;
  letter-spacing: 0.15px;
  color: #404040;
  line-height: 1.5;
  font-size: 1.143rem;
  font-family: 'Surt-Medium', sans-serif;

}

.select-field > div div{
  padding: 0;
  margin: 0;
}

.nav-buttons.content {
  min-height: auto;
}

.report__title{
    color: #404040;
    margin-bottom: 16px;
    text-transform: uppercase;

  }

.report__header{
    display: flex;
    margin-bottom: 20px;
  }

.report__header-text{
    max-width: 72%;
    width: 100%;
  }

.report__header-icons{
    max-width: 28%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

.report__date{
    line-height: 1.14;
    color: #979797;
  }

.report__descr{
    color: #404040;
  }

.report__icon{
    cursor: pointer;
    fill: #187BE3
  }

.report__icon:not(:last-child){
      margin-right: 27px;
    }

.report__text + .report__text{
    border-top: 1px solid #C4C4C4;
    padding-top: 50px;
    margin-top: 35px;
  }

.report__inner{
    background-color: #F9F9F9;
    padding: 0 40px 32px;
    margin-right: 40px;
  }

.report__card{
    background-color: #E6FFFE;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 1px 7px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 32px 36px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

.report__card-text{
      padding-right: 30px;
    }

.report__card-title{
      color: #404040;
      margin-bottom: 30px;
    }

.report__card-descr{
      color: #404040;
      margin-bottom: 25px;
    }

.report__card-progress{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

.report__card-link{
      font-size: 1rem;
      line-height: 1.29;
      text-decoration: none;
      color: #187BE3;
    }

.report__progress-caption{
    margin-top: 20px;
  }

.report__recom{
    display: flex; 
    justify-content: space-between; 
    margin-bottom: 10px;
  }

.report__recom-item{
      width: calc(100% / 3 - 15px);
      box-sizing: border-box;
      background-color: #fff;      
      border-radius: 8px;
      padding: 16px; 
      -webkit-filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.05)); 
              filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.05));    
    }

.report__footer{
    padding: 35px 40px;
    color: #797979;
  }

.report__footer-title{
      color: #979797;
      display: flex;
      margin-bottom: 8px
    }

.report__footer-title::before{
        content: '';
        background-image: url(../../static/media/lock.6e408c3e.svg);
        width: 15px;
        height: 20px;
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 15px;
      }

.report__footer-descr{
      margin-left: 30px;
      font-size: 0.929rem;
      line-height: 1.23;
    }

.report__footer-bottom{
      margin-top: 30px;
      border-top: 1px solid #C4C4C4;
      display: flex;
      padding: 30px 20px 0;
      margin-bottom: 15px;
    }

.report__footer-text{
      width: 43%;
    }

.report__footer-cols{
      width: 57%;
      display: flex;
    }

.report__footer-cols p, .report__footer-cols a{
        margin: 0;
        font-size: 0.929rem;
        line-height: 1.23;
        color: #797979;
        text-decoration: none;
      }

.report__footer-item{
      width: 50%;
    }

@media (max-width: 1092px) {
    .report__inner{
      margin-right: 0;
    }
}

@media (max-width: 808px) {
    .report__card{
      flex-direction: column-reverse;
    }
      .report__card-progress{
        margin-bottom: 20px;
      }
      .report__card-title{
        margin-bottom: 15px;
        text-align: center;
      }
      .report__card-descr{
        margin-bottom: 20px;
      }
    .report__recom{
      flex-wrap: wrap;
    }
    
      .report__recom-item{
        width: 100%;
        margin-bottom: 40px;
      }
    .report__footer-bottom{
      flex-wrap: wrap;
    }
    .report__footer-text, .report__footer-cols{
      width: 100%;
    }
    .report__footer-text{
      margin-bottom: 20px;
    }

    .report__card-text{
      padding-right: 0;
    }
}

@media (max-width: 616px) {
    .report__header{
      flex-wrap: wrap;
    }
      .report__header-text, .report__header-icons{
        max-width: 100%;
      }
    .report__footer-cols{
      flex-wrap: wrap;
    }
    .report__footer-item{
      width: 100%
    }
      .report__footer-item:not(:last-child){
        margin-bottom: 20px;
      }
    .report__inner{
      padding: 0 15px 32px;
    }
    .report__card{
      padding: 32px 20px;
    }
  
}

.recommendation{
  margin-top: 25px;
  margin-bottom: 24px;
}

.recommendation__item{
    display: flex;
    flex-direction: row;
    background: #fff;    
    border-radius: 8px;
    padding: 32px 24px;
    -webkit-filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.05));
            filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.05))
  }

.recommendation__item:not(:last-child){
      margin-bottom: 24px;
    }

.recommendation__icon{    
    width: 85px;       
  }

.recommendation__text{
    width: calc(100% - 85px);
    padding-left: 24px;
  }

.recommendation__title{
    color: #404040;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

.recommendation__descr{
    color: #828282;
    margin-bottom: 0;
  }

.recommendation__descr a {
      color: #187BE3;
      text-decoration: none;
    }

.recommendation__descr p:last-child {
      margin: 0;
    }

.recommendation__benefits {
    margin-top: 24px;
  }

.recommendation__benefits-note {
      margin-top: 8px;
      display: block;
      font-size: 14px;
      line-height: 18px;
      color: #828282;
    }

.recommendation__benefits-count {
      color: #00D0C4;
      display: block;
      font-size: 24px;
      font-weight: 700;      
    }

.recommendation__benefits-link {
      margin-top: 16px;
      display: inline-block;
      color: #187BE3;
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      text-decoration: none;
    }

.recom-item{
  display: flex;
  flex-direction: column;
  
}

.recom-item__image{
    width: 60px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 16px;
  }

.recom-item__title{
    color: #404040;
    margin-bottom: auto;
    text-align: center;
    font-family: "Avenir", sans-serif;
    font-size: 1.286rem;
    line-height: 1.278;
    font-weight: 600;
    text-decoration: none;
    display: block;
  }

.recom-item__descr{
    padding-top: 8px;
    color: #828282;
    text-align: center;
    margin-bottom: 0;
  }

.recom-item__button{
    max-width: 143px;
    margin: 5px auto 0;
    width: 100%;
  }

@media (max-width: 808px) {
    .recom-item__button{
      margin-top: 15px;
    }
    .recommendation__icon{
      display: none;
    }

    .recommendation__item{
      padding: 32px 20px;
    }

    .recommendation__text{
      width: 100%;
      padding-left: 0;
    }
}

.footer {
  position: relative;
  overflow: hidden;
  padding-bottom: 116px;
  font-family: 'Surt-Medium', sans-serif;
  font-weight: 400;   
}

.footer .container {
  justify-content: space-between;
  max-width: 1280px;
  position: relative;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  z-index: 1;
}

.footer li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer li.btn {
  background-color: transparent;
  border: none;
  color: #26275f;
  font-weight: 400;
  padding-left: 0;
  margin-left: 35px;
}

.footer li.btn a {
  padding: 5px 15px;
}

.footer a {
  display: inline-block;
  color: #0B1028;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer a.footer__privacy {
  color: #414141;
}

.footer__top {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.footer__top h2, .footer__top h3 {
  text-transform: uppercase;
  color: #26275f;
  font-weight: 900;
  font-size: 1.1rem;
  line-height: 2rem;
  padding-bottom: 25px;
  letter-spacing: 1px;
}

@media (max-width: 1024px) {
  .footer__top h2, .footer__top h3 {
    padding-bottom: 12px;
  }
}

.footer__left {
  position: relative;  
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  padding-bottom: 16px;
}

@media (max-width: 768px) {
  .footer__left {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

.footer__left h2 {
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #26275f;
  letter-spacing: -0.6px;
  text-transform: none;
  padding-bottom: 37px;
}

.footer__right {  
  padding-bottom: 16px;
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .footer__right {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  .footer__right {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding-top: 50px;
    padding-left: 0;
    padding-bottom: 32px;
    margin-top: 30px;
    flex-flow: row wrap;
    border-top: 1px solid #979797;
  }
}

.footer__bottom {
  border-top: 1px solid rgba(151, 151, 151, 0.25);
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  padding: 22px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .footer__bottom {
    justify-content: center;
  }
}

.footer__bottom ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__bottom li {
  list-style: none;
}

@media (max-width: 768px) {
  .footer__social-holder {
    padding-bottom: 0;
  }
}

.footer__social-holder ul {
  list-style: none;
  display: flex;
  padding: 0;  
}

.footer__social-holder li {
  list-style: none;
  padding-right: 19px;
  font-size: 20px;
  color: #0B1028;
}

.footer__nav {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;  
}

.footer__nav ul {
  list-style: none;
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

@media (max-width: 1024px) {
  .footer__nav ul {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .footer__nav ul {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.footer__nav li {
  list-style: none;
  padding: 0 0 0 32px;
  letter-spacing: 0.5px;
  font-size: 15px;
  line-height: 15px;
}

@media (max-width: 1024px) {
  .footer__nav li {
    padding: 0 0 0 12px;
  }
}

@media (max-width: 768px) {
  .footer__nav li {
    padding: 0 0 24px;
  }
}

.footer__nav li:first-of-type {
  padding: 0;
}

@media (max-width: 768px) {
  .footer__nav li:first-of-type {
    padding: 0 0 24px;
  }
}

.footer__nav li:last-of-type {
  font-weight: 800;
  letter-spacing: -0.1px;
}

@media (max-width: 768px) {
  .footer__nav li:last-of-type {
    padding: 0;
  }
}

.footer__nav li span {
  display: flex;
  align-items: center;
}

.footer__nav li svg {
  margin-right: 10px;
}

.footer__disclaimer {
  font-size: 12px;
  line-height: 20px;
  color: #2d3e50;
  flex: 0 0 56%;
  width: 56%;
  max-width: 56%;
}

.footer__disclaimer p {
    font-size: 12px;
    line-height: 20px;
  }

@media (max-width: 1024px) {
  .footer__disclaimer {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

.footer__copyright {
  flex: 0 0 44%;
  width: 44%;
  max-width: 44%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row nowrap;
  font-size: 12px;
  line-height: 1.67;
  color: #2d3e50;
}

@media (max-width: 1024px) {
  .footer__copyright {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    margin-top: 32px;
  }
}

@media (max-width: 768px) {
  .footer__copyright {
    padding-top: 10px;
    flex-flow: column nowrap;
    align-items: center;
  }
}

.footer__copyright-text {
  flex: 0 1 auto;
  text-align: right;
  margin-right: 30px;
}

@media (max-width: 768px) {
  .footer__copyright-text {
    margin-right: 0;
    margin-bottom: 24px;
    text-align: left;
  }
}

.footer__copyright-text p {
  display: inline;
  font-size: 12px;
  line-height: 20px;
}

.footer__privacy {
  flex: 0 1 auto;
  text-decoration: none;
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
}

.footer__social-holder li svg {
  fill: #0b1028;
  stroke: #0b1028;
}

.svg-inline--fa.fa-w-14 {
  width: .875em;
}

.svg-inline--fa.fa-w-9 {
  width: .5625em;
}

.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.hubspot {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .8);   
  font-family: "Surt-Medium", sans-serif;
  font-weight: 400;
  display: none;
}

.hubspot__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto; 
    padding: 30px 45px;
  }

.hubspot .section-title {
    padding-bottom: 15px;
    font-size: 18px;
    line-height: 24px;    
    font-weight: 900
  }

@media (max-width: 575px) {

  .hubspot .section-title {
      font-size: 15px;
      line-height: 22px
  }
    }

.hubspot * {
    box-sizing: border-box;
  }

.hubspot-form {
  margin: auto;
  max-width: 500px;
  background-color: #26275f;
  color: #fff;
  padding: 30px 22px 30px 30px;
  opacity: 1;
  box-sizing: border-box;
}

.hubspot-form h2 {
  color: #e1e88a;
}

.hubspot-form .hs-form .hs-form-field, .hubspot-form .form .hs-form-field {
  position: relative;
}

.hubspot-form .hs-form select, .hubspot-form .hs-form input, .hubspot-form .form select, .hubspot-form .form input {
  font-family: "Surt-Medium", sans-serif;
  background-color: transparent;
  border: none;
  color: #fff;
  border-bottom: 1px solid #4E4F80;
  font-weight: 100;
  font-size: 14px;
  line-height: 24px;
  padding: 20px 5px 10px;
  margin: 10px 0;
  width: 100% !important;
}

@media (max-width: 575px) {
  .hubspot-form .hs-form select, .hubspot-form .hs-form input, .hubspot-form .form select, .hubspot-form .form input {
    padding: 15px 0 10px;
    margin: 7px 0;
  }
}

.hubspot-form .hs-form select option, .hubspot-form .form select option {
  color: #26275f;
}

.hubspot-form .hs-form .hs_submit, .hubspot-form .form .hs_submit {
  padding-right: 8px;
}

.hubspot-form .hs-form input[type="submit"], .hubspot-form .form input[type="submit"] {
  margin-top: 40px;
  width: auto;
  -webkit-appearance: none;
  position: relative;
  display: inline-block;
  transition: all .45s ease;
  padding: 13px 15px;
  font-family: "Surt-Medium", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  color: #26275f;
  background-color: #e1e88a;
  border: 2px solid #e1e88a;
}

@media (max-width: 575px) {
  .hubspot-form .hs-form input[type="submit"], .hubspot-form .form input[type="submit"] {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }
}

.hubspot-form .hs-form input[type="submit"]:hover, .hubspot-form .form input[type="submit"]:hover {
  color: #e1e88a;
  cursor: pointer;
  background-color: transparent;
}

.hubspot-form .hs-form .hs-error-msgs, .hubspot-form .form .hs-error-msgs {
  position: absolute;
  list-style: none;
  padding: 0;
  color: #e1e88a;
  top: 65px;
  right: 10px;
}

.hubspot-form .hs-form .hs-fieldtype-select .hs-error-msgs, .hubspot-form .form .hs-fieldtype-select .hs-error-msgs {
  top: 60px;
}

.hubspot-form .hs-form label, .hubspot-form .form label {
  display: none;
}

.hubspot-form .hs-form label.hs-error-msg, .hubspot-form .form label.hs-error-msg {
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.hubspot-form .hs-form ::-webkit-input-placeholder, .hubspot-form .form ::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}

.hubspot-form .hs-form :-moz-placeholder, .hubspot-form .form :-moz-placeholder {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #fff;
}

.hubspot-form .hs-form ::-moz-placeholder, .hubspot-form .form ::-moz-placeholder {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #fff;
}

.hubspot-form .hs-form :-ms-input-placeholder, .hubspot-form .form :-ms-input-placeholder {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #fff;
}
